import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  question:string = '';
  answer: string  = '';
  type: string = 'add';
  submitted:boolean = false;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit() {
    if(this.data.data.type==='edit'){
      this.question = this.data.data.ques;
      this.answer = this.data.data.ans;
      this.type = this.data.data.type
    }
  }

  submit(){
    this.submitted = true;
    if(this.question.length && this.answer.length){
      let data = {
        ques: this.question,
        ans: this.answer
      }
      this.dialogRef.close(data);
    }
  }

}
