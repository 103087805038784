<form>
  <div class="login-form">
    <div class="form-group w-100">
      <mat-label class="required">Question</mat-label>
      <mat-form-field>
        <input matInput type="text" name="question" [(ngModel)]="question" />
      </mat-form-field>
      <p class="text-danger" *ngIf="submitted&&!question.length">Question is required</p>
    </div>
    <div class="form-group w-100">
      <mat-label class="required">Answer</mat-label>
      <mat-form-field>
        <input matInput type="text" name="answer" [(ngModel)]="answer" />
      </mat-form-field>
      <p class="text-danger" *ngIf="submitted&&!answer.length">Answer is required</p>
    </div>
    <div class="form-group">
      <button class="btn btn-primary" (click)="submit()">{{type==='add'?'Add':'Update'}}</button>
    </div>
  </div>
</form>
